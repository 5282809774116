import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import NaviBar from "./components/Navbar";
import Footer from "./components/Footer";
import Phone from "./components/Phone";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Home from "./Home";
import { Product } from "./Product";
import { Gallery } from "./Gallery";
import { Stocks } from "./Stocks";
import { Buy } from "./Buy";

function App() {
  return (
    <>
      <Router>
        <NaviBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/Product" component={Product} />
          <Route path="/Gallery" component={Gallery} />
          <Route path="/Stocks" component={Stocks} />
          <Route path="/Buy" component={Buy} />
        </Switch>
      </Router>
      <Phone />
      <Footer />
    </>
  );
}

export default App;
